import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Box, Button, ButtonProps, DialogActions } from '@mui/material';
import { type FC } from 'react';

type Props = {
  onClose?: () => void;
  dirtyForm?: boolean;
  onResetForm?: () => void;
  /**
   * Use 'submit' if you have the submit function
   * on a form element, otherwise pass the function
   */
  onSubmit?: (() => void) | 'submit';
  submitLoading?: boolean;
  submitLabel?: string;
  submitDisabled?: boolean;
  customButtons?: {
    label: string;
    onClick: () => void;
    color?: 'inherit' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
    disabled?: boolean;
    loading?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
  }[];
};

const ModalActions: FC<Props> = (props) => {
  const ModalButton: FC<ButtonProps & { loading?: boolean }> = ({ loading, ...props }) => {
    if (loading)
      return (
        <LoadingButton loading={loading} {...props}>
          {props.children}
        </LoadingButton>
      );
    else return <Button {...props}>{props.children}</Button>;
  };

  return (
    <DialogActions sx={{ alignItems: 'center', justifyContent: 'space-between', mx: 2 }}>
      {props.onResetForm != null && (
        <Button
          disabled={props.dirtyForm === false}
          onClick={props.onResetForm}
          variant="outlined"
          color="info"
        >
          <Trans>Restablecer formulario</Trans>
        </Button>
      )}
      {props.customButtons?.map(({ label, ...props }) => (
        <ModalButton variant="outlined" color="info" key={label} {...props}>
          {label}
        </ModalButton>
      ))}
      <Box sx={{ flexGrow: 1 }}></Box>
      <Button onClick={props.onClose} variant="outlined" color="info">
        Cerrar
      </Button>
      {props.onSubmit != null && (
        <ModalButton
          loading={props.submitLoading}
          {...(typeof props.onSubmit === 'function'
            ? { onClick: props.onSubmit }
            : { type: 'submit' })}
          color="primary"
          variant="contained"
          disabled={props.dirtyForm === false || props.submitDisabled}
        >
          {props.submitLabel ?? <Trans>Guardar</Trans>}
        </ModalButton>
      )}
    </DialogActions>
  );
};

export default ModalActions;
