import { combineReducers } from '@reduxjs/toolkit';
import dgaDevicesReducer from '@features/DGAReports/slices/dgaDevices';
import { reducer as announcementsReducer } from '@features/announcements/slices/announcementsSlice';
import { devicesAnalysisReducer } from '@features/devicesAnalysis';
import controlCenterReducer from '@features/controlCenter/slices/controlCenterSlice';
import { reducer as debtsAlertReducer } from '@features/debtsAlert/slices/debtsAlertSlice';
import devicesSupportReducer from '@features/deviceSupport/slices/devicesSupport';
import { reducer as maipoReducer } from '@features/distributionMaipo/slices/maipoSlice';
import { reducer as integrationsReducer } from '@features/integrations/slices/integrations';
import componentsReducer from '@/slices/components';
import { reducer as GlobalConfig } from '@/slices/config';
import distributionReducer from '@/slices/distribution';
import { reducer as gatesReducer } from '@/slices/gates';
import { reducer as mapReducer } from '@/slices/map';
import { reducer as postsNews } from '@/slices/postsNews';
import stationsReducer from '@/slices/stations';
import modalsReducer from '@/slices/modals';
import gatesInMovementReducer from '@features/gateAdministration/slices/reducer';
import userProfileReducer from '@features/userProfile/slices/userProfileSlice';
import userPreferencesReducer from '@features/userPreferences/slices/userPreferencesSlice';
import deviceProfileReducer from '@features/deviceProfile/slices/deviceProfileSlice';
import dgaReducer from '@features/dga/slices/dgaSlice';

const rootReducer = combineReducers({
  map_store: mapReducer,
  global_config: GlobalConfig,
  distribution_store: distributionReducer,
  stations_store: stationsReducer,
  gates_store: gatesReducer,
  integrations_store: integrationsReducer,
  components_store: componentsReducer,
  posts_news: postsNews,
  devices_support_store: devicesSupportReducer,
  maipo_sira_store: maipoReducer,
  dga_devices_store: dgaDevicesReducer,
  dga_store: dgaReducer,
  debts_alert_store: debtsAlertReducer,
  control_center_store: controlCenterReducer,
  announcements_store: announcementsReducer,
  devices_analysis_store: devicesAnalysisReducer,
  gates_in_movement_store: gatesInMovementReducer,
  user_profile_store: userProfileReducer,
  user_preferences_store: userPreferencesReducer,
  device_profile_store: deviceProfileReducer,
  modals_store: modalsReducer,
});

export default rootReducer;
