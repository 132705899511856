import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type ModalRequiredDevice =
  | {
      open: true;
      deviceId: string;
    }
  | {
      open: false;
    };

interface ModalsState {
  updateDeviceAlertsModal: {
    open: boolean;
    deviceId?: string;
  };
}

const initialState: ModalsState = {
  updateDeviceAlertsModal: {
    open: false,
  },
};

const { reducer, actions } = createSlice({
  name: 'modals_store',
  initialState,
  reducers: {
    setUpdateDeviceAlertsModal: (
      state,
      action: PayloadAction<ModalsState['updateDeviceAlertsModal']>,
    ) => {
      state.updateDeviceAlertsModal = action.payload;
    },
  },
});

export default reducer;
export const { setUpdateDeviceAlertsModal } = actions;
