import {
  DgaType,
  GetDgaConfigurationQuery,
  GetDgaConfigurationQueryVariables,
  UpdateDgaConfigurationMutation,
  UpdateDgaConfigurationMutationVariables,
} from '@/__generated__/graphql';
import { useModalStatusMessage } from '@/hooks';
import {
  MutationTuple,
  QueryRef,
  useLoadableQuery,
  useMutation,
  useReadQuery,
} from '@apollo/client';
import StandardAutocompleteIdsWithFallback from '@features/standardDesign/components/autocomplete/StandardAutocompleteIds';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Info, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Suspense, useEffect, useState, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { useDispatch, useSelector } from '@/store';
import UPDATE_DGA_CONFIGURATION from '../../graphql/mutations/updateDgaConfiguration';
import GET_DGA_CONFIGURATION from '../../graphql/queries/getDGAConfiguration';
import {
  setCreateDGAConfigurationModal,
  setUpdateDGAConfigurationModal,
  setUpdateDGAScheduleModal,
} from '../../slices/dgaSlice';
import DeleteDGAConfigurationConfirmationModal from './DeleteDGAConfigurationConfirmationModal';
import UpdateDGAScheduleModal from './UpdateDGAScheduleModal';
import { getUpdateDgaConfigurationMutationSchema } from '../../utils/validationSchemas';

type FormValues = UpdateDgaConfigurationMutationVariables;

const Component: FC<{
  onClose: () => void;
  mutation: MutationTuple<UpdateDgaConfigurationMutation, UpdateDgaConfigurationMutationVariables>;
  queryRef: QueryRef<GetDgaConfigurationQuery, GetDgaConfigurationQueryVariables>;
}> = ({ onClose, mutation, queryRef }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const { i18n, _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { data } = useReadQuery(queryRef);
  const deviceId = data.device.id;
  const [updateDgaConfiguration, { loading }] = mutation;
  const [showPassword, setShowPassword] = useState(false);

  const defaultValues = {
    deviceId,
    input: {
      code: data.device?.dgaConfiguration?.code || '',
      rut: data.device?.dgaConfiguration?.rut || '',
      password: data.device?.dgaConfiguration?.password || '',
      type: data.device?.dgaConfiguration?.type || DgaType.Surface,
    },
  };

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(getUpdateDgaConfigurationMutationSchema(i18n)),
  });

  const hasDGAConfiguration = data?.device?.dgaConfiguration != null;

  const resetForm = () => {
    methods.reset();
  };

  const onSubmit = async (values: FormValues) => {
    const { deviceId, input } = values;
    await updateDgaConfiguration({
      variables: {
        deviceId,
        input,
      },
      onError: (error) => {
        openModalErrorMessage(error.message);
      },
      onCompleted: (data) => {
        openModalSuccessMessage(_(msg`Configuración DGA actualizada correctamente`));
        methods.reset({
          deviceId,
          input: {
            code: data.updateDgaConfiguration.dgaConfiguration?.code,
            rut: data.updateDgaConfiguration.dgaConfiguration?.rut,
            password: data.updateDgaConfiguration.dgaConfiguration?.password,
            type: data.updateDgaConfiguration.dgaConfiguration?.type,
          },
        });
        onClose();
      },
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            __typename: 'Device',
            id: deviceId,
          }),
          fields: {
            dgaConfiguration() {
              return data.updateDgaConfiguration.dgaConfiguration;
            },
          },
        });
      },
      onQueryUpdated(observableQuery) {
        if (observableQuery.queryName !== 'StandardAutocompleteData') {
          return observableQuery.refetch();
        }
      },
    });
  };

  const handleCreateConfiguration = () => {
    dispatch(
      setUpdateDGAConfigurationModal({
        open: false,
      }),
    );
    dispatch(
      setCreateDGAConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  return (
    <>
      <DeleteDGAConfigurationConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        deviceId={deviceId}
        onSuccesfulDelete={onClose}
      />
      <UpdateDGAScheduleModal />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Controller
            name="deviceId"
            control={methods.control}
            render={({ field, fieldState }) => (
              <StandardAutocompleteIdsWithFallback
                dataType="devices"
                label={_(msg`Dispositivo`)}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                value={field.value || null}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                disabled
              />
            )}
          />
          {!hasDGAConfiguration ? (
            <Box mt={2}>
              <Box display="flex" gap={1} alignItems="center" mb={1}>
                <Info fontSize="small" color="warning" />
                <Typography>
                  <Trans>El dispositivo no tiene configuración DGA</Trans>
                </Typography>
              </Box>
              <Button variant="contained" color="primary" onClick={handleCreateConfiguration}>
                <Trans>Crear configuración</Trans>
              </Button>
            </Box>
          ) : (
            <Box
              display="grid"
              gridTemplateColumns={{
                xs: '1fr',
                md: '1fr 1fr',
              }}
              gap={2}
              mt={2}
            >
              <Controller
                name="input.type"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    options={Object.values(DgaType)}
                    disableClearable
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    value={field.value ?? undefined}
                    getOptionLabel={(value) => {
                      if (value === DgaType.Surface) return _(msg`Superficial`);
                      if (value === DgaType.PipeSurface) return _(msg`Superficial con flujómetro`);
                      if (value === DgaType.Underground) return _(msg`Subterránea`);
                      return value;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={_(msg`Tipo de extracción`)}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="input.code"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={_(msg`Código DGA`)}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="input.rut"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={_(msg`RUT`)}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="input.password"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label={_(msg`Contraseña`)}
                    autoComplete="new-password"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword((showPassword) => !showPassword)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          )}
        </DialogContent>
        <ModalActions
          onClose={onClose}
          onSubmit="submit"
          onResetForm={resetForm}
          dirtyForm={!hasDGAConfiguration ? false : methods.formState.isDirty}
          submitLoading={loading}
          customButtons={[
            {
              label: _(msg`Eliminar configuración`),
              color: 'error',
              onClick: () => {
                setOpenDeleteModal(true);
              },
              disabled: !hasDGAConfiguration,
            },
            {
              label: _(msg`Editar recurrencia`),
              onClick: () => {
                dispatch(
                  setUpdateDGAScheduleModal({
                    open: true,
                    deviceId,
                  }),
                );
              },
              disabled: !hasDGAConfiguration,
            },
          ]}
        />
      </form>
    </>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => (
  <>
    <DialogContent dividers>
      <Skeleton variant="rounded" height={400} />
    </DialogContent>
    <ModalActions onClose={onClose} />
  </>
);

const UpdateDGAConfigurationModal: FC = () => {
  const dispatch = useDispatch();
  const { updateDGAConfigurationModal } = useSelector((state) => state.dga_store);
  const mutation = useMutation(UPDATE_DGA_CONFIGURATION);
  const [loadQuery, queryRef] = useLoadableQuery(GET_DGA_CONFIGURATION, {});

  const onClose = () => {
    dispatch(
      setUpdateDGAConfigurationModal({
        open: false,
      }),
    );
  };

  useEffect(() => {
    if (updateDGAConfigurationModal.open) {
      loadQuery({
        deviceId: updateDGAConfigurationModal.deviceId,
      });
    }
  }, [updateDGAConfigurationModal.open]);

  return (
    <Dialog onClose={onClose} open={updateDGAConfigurationModal.open} maxWidth="md" fullWidth>
      <ModalTitle onClose={onClose} title={<Trans>Editar configuración DGA</Trans>} />
      <Suspense fallback={<Fallback onClose={onClose} />}>
        {queryRef && <Component onClose={onClose} mutation={mutation} queryRef={queryRef} />}
      </Suspense>
    </Dialog>
  );
};

export default UpdateDGAConfigurationModal;
