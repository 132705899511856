import ChangeStatusDevice from '@features/changeStatusDevice/components/ChangeStatusDevice';
import { CloneDevice } from '@features/deviceClone/components/CloneDevice';
import { DeprecatedDeviceProfile } from '@features/deviceProfile';
import UpdateStationComponent from '@features/stationUpdate/components/UpdateStation';
import { Device } from 'src/types/device';
import {
  CreateMeasurement,
  DeleteStation,
  DeviceNameAliases,
  DeviceNotifications,
  HistoryStatusDevice,
  ManualDataDetails,
  StatusMaintenance,
} from '.';
import { DialogEnum } from '../DialogEnum';
import DeviceConfigurationModal from './DeviceConfigurationsModal';
import AccessEndAlarm from './accessEndAlarm';

interface Props {
  dialogToShow: DialogEnum;
  handleDialogClose: (refetch: boolean) => void;
  device: Device;
}

const DialogComponents = ({ dialogToShow, handleDialogClose, device }: Props) => {
  const handleEditClose = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleCloseChangeStatus = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleCloseManualDataInfo = () => {
    handleDialogClose(false);
  };

  const handleStatusHistoryClose = () => {
    handleDialogClose(false);
  };

  const handleUserListClose = () => {
    handleDialogClose(false);
  };

  const handleDeleteClose = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleCreateMeasurementClose = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleStatusMaintenanceClose = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleDeviceNotificationsClose = () => {
    handleDialogClose(false);
  };

  const handleDeviceNameAliasesClose = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleRadarParamsClose = () => {
    handleDialogClose(false);
  };

  const handleCloseClone = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  return (
    <>
      <DeviceConfigurationModal
        open={dialogToShow === DialogEnum.configurations}
        onClose={() => handleDialogClose(false)}
        device={device}
      />
      <ChangeStatusDevice
        show={dialogToShow === DialogEnum.changeStatus}
        device={device}
        handleClose={handleCloseChangeStatus}
      />
      <ManualDataDetails
        show={dialogToShow === DialogEnum.manualData}
        device={device}
        handleClose={handleCloseManualDataInfo}
      />
      <UpdateStationComponent
        show={dialogToShow === DialogEnum.edit}
        device={device}
        handleClose={handleEditClose}
      />
      <HistoryStatusDevice
        show={dialogToShow === DialogEnum.statusHistory}
        device={device}
        handleClose={handleStatusHistoryClose}
      />
      <AccessEndAlarm
        show={dialogToShow === DialogEnum.userList}
        device={device}
        handleClose={handleUserListClose}
      />
      <DeleteStation
        show={dialogToShow === DialogEnum.delete}
        device={device}
        handleClose={handleDeleteClose}
      />
      <CreateMeasurement
        show={dialogToShow === DialogEnum.createMeasurement}
        device={device}
        handleClose={handleCreateMeasurementClose}
      />
      <StatusMaintenance
        show={dialogToShow === DialogEnum.statusMaintenance}
        device={device}
        handleClose={handleStatusMaintenanceClose}
      />
      <DeviceNotifications
        show={dialogToShow === DialogEnum.deviceNotifications}
        device={device}
        handleClose={handleDeviceNotificationsClose}
      />
      <DeviceNameAliases
        show={dialogToShow === DialogEnum.deviceNameAliases}
        device={device}
        handleClose={handleDeviceNameAliasesClose}
      />
      <DeprecatedDeviceProfile
        show={dialogToShow === DialogEnum.deviceProfile}
        device={device}
        handleClose={handleRadarParamsClose}
      />
      <CloneDevice
        show={dialogToShow === DialogEnum.clone}
        device={device}
        handleClose={handleCloseClone}
      />
    </>
  );
};

export default DialogComponents;
